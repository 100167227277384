import type { UuidV4 } from '@lazr/types'
import {
    BillDutiesToParty, CarrierType,
    Country, Currency,
    DimensionUnit,
    FedexCarrierCode,
    FedexLocation,
    PickupStatus,
    Provider,
    TransportType,
    WeightUnit,
} from '@lazr/enums'
import { OtherUser, OtherUserProps } from './OtherUser'

export interface SchedulePickupAttributes {
    carrierId: UuidV4
    transportType: TransportType
    date: string
    openTime: string
    closeTime: string
    timezone: string
    address: PickupAddress
    shipperAddress: PickupAddress
    instructions: string | null
    organizationProviderAccountId: UuidV4 | null
    destinationCountry: Country | null
    notify: boolean | null
    billDutiesToParty: BillDutiesToParty | null
    dhlSpecifics?: PickupDhlSpecifics
    fedexSpecifics?: PickupFedexSpecifics
    canadaPostSpecifics?: PickupCanadaPostSpecifics
    upsSpecifics?: PickupUpsSpecifics
    purolatorSpecifics?: PickupPurolatorSpecifics
    nationexSpecifics?: PickupNationexSpecifics
    canparSpecifics?: PickupCanparSpecifics
}
interface PickupAddress {
    companyName: string
    contactName: string
    contactEmails: string[]
    contactPhone: string
    contactPhoneExtension: string | null
    streetAddress: string
    streetAddress2: string | null
    streetAddress3: string | null
    city: string
    state: string
    country: Country
    postalCode: string
}

interface PickupFedexSpecifics {
    ParcelPakEnvelope?: {
        destinationDeliveryInstructions: string | null
        serviceLevelCarrierCode: FedexCarrierCode
        location: FedexLocation
        quantity: number
        totalWeight: number
        totalWeightUnit: WeightUnit
    }
}
interface PickupCanadaPostSpecifics {
    ParcelPakEnvelope?: {
        destinationDeliveryInstructions: string | null
        quantity: number
    }
}
interface PickupUpsSpecifics {
    ParcelPakEnvelope?: {
        serviceLevelId: UuidV4
        isResidential: boolean
        totalWeight: number
        totalWeightUnit: WeightUnit
        quantity: number
    }
}
interface PickupPurolatorSpecifics {
    ParcelPakEnvelope?: {
        totalWeight: number
        totalWeightUnit: WeightUnit
        quantity: number

    }
}
interface PickupNationexSpecifics {
    ParcelPakEnvelope?: {
        quantity: number
    }
}

interface PickupDhlSpecifics {
    ParcelPakEnvelope?: {
        serviceLevelId: UuidV4
        dutyBillToAccountNumber: string | null
        quantity: number
        quoteSupportEtd: boolean | null
        totalDeclaredValue: number | null
        totalDeclaredValueCurrency: Currency | null
        isResidential: boolean
        totalWeight: number
        totalWeightUnit: WeightUnit
        units: DhlSpecificsPickupUnit[]
    }
}

interface DhlSpecificsPickupUnit {
    weight: number
    weightUnit: WeightUnit
    height: number
    length: number
    width: number
    dimensionUnit: DimensionUnit
    quantity: number
}

interface PickupCanparSpecifics {
    ParcelPakEnvelope?: {
        totalWeight: number
        totalWeightUnit: WeightUnit
        quantity: number
        instructions: string
    }
}

export interface PickupUnit {
    id: UuidV4
    weight: number | null
    weightUnit: WeightUnit | null
    height: number | null
    length: number | null
    width: number | null
    dimensionUnit: DimensionUnit | null
    quantity: number | null
}

export interface PickupProps {
    id: UuidV4
    carrier: {
        id: UuidV4
        name: string
        apiCarrierAccountCode: string
        type: CarrierType
        idValue: string
    }
    organizationId: UuidV4
    date: string
    organizationProviderAccountId: UuidV4 | null
    address: PickupAddress
    shipperAddress: PickupAddress
    provider: Provider
    transportType: TransportType | null
    serviceLevel: {
        id: UuidV4 | null
        code: string | null
    }
    serviceLevelCarrierCode: FedexCarrierCode | null
    pickupNumber: string | null
    status: PickupStatus
    location: FedexLocation | null
    instructions: string | null
    openTime: string
    closeTime: string
    destinationCountry: Country | null
    notify: boolean | null
    destinationDeliveryInstructions: string | null
    isResidential: boolean | null
    units: PickupUnit[]
    billDutiesToParty: BillDutiesToParty | null
    dutyBillToAccountNumber: string | null
    quoteSupportEtd: boolean | null
    totalDeclaredValue: number | null
    timezone: string | null
    totalDeclaredValueCurrency: Currency | null
    quantity: number | null
    totalWeight: number | null
    totalWeightUnit: WeightUnit | null
    createdAt: string
    updatedAt: string
    createdByUser: OtherUserProps | null
}

export class Pickup {
    public id: UuidV4
    public carrierId: UuidV4
    public carrier: {
        id: UuidV4
        name: string
        apiCarrierAccountCode: string
        type: CarrierType
        idValue: string
    }
    public organizationId: UuidV4
    public date: string
    public organizationProviderAccountId: UuidV4 | null
    public address: PickupAddress
    public shipperAddress: PickupAddress
    public provider: Provider
    public transportType: TransportType | null
    public serviceLevelCode: string | null
    public pickupNumber: string | null
    public status: PickupStatus
    public serviceLevel: {
        id: UuidV4 | null
        code: string | null
    }
    public serviceLevelCarrierCode: FedexCarrierCode | null
    public location: FedexLocation | null
    public openTime: string
    public closeTime: string
    public instructions: string | null
    public destinationCountry: Country | null
    public notify: boolean | null
    public destinationDeliveryInstructions: string | null
    public isResidential: boolean | null
    public units: PickupUnit[]
    public billDutiesToParty: BillDutiesToParty | null
    public dutyBillToAccountNumber: string | null
    public quoteSupportEtd: boolean | null
    public totalDeclaredValue: number | null
    public timezone: string | null
    public totalDeclaredValueCurrency: Currency | null
    public quantity: number | null
    public totalWeight: number | null
    public totalWeightUnit: WeightUnit | null
    public createdAt: string
    public updatedAt: string
    public createdByUser: OtherUser | null

    constructor (props: PickupProps) {
        this.id = props.id
        this.carrier = props.carrier
        this.organizationId = props.organizationId
        this.address = props.address
        this.shipperAddress = props.shipperAddress
        this.date = props.date
        this.organizationProviderAccountId = props.organizationProviderAccountId
        this.provider = props.provider
        this.transportType = props.transportType
        this.pickupNumber = props.pickupNumber
        this.status = props.status
        this.serviceLevel = props.serviceLevel
        this.serviceLevelCarrierCode = props.serviceLevelCarrierCode
        this.location = props.location
        this.openTime = props.openTime
        this.closeTime = props.closeTime
        this.instructions = props.instructions
        this.destinationCountry = props.destinationCountry
        this.notify = props.notify
        this.destinationDeliveryInstructions = props.destinationDeliveryInstructions
        this.isResidential = props.isResidential
        this.units = props.units
        this.billDutiesToParty = props.billDutiesToParty
        this.dutyBillToAccountNumber = props.dutyBillToAccountNumber
        this.quoteSupportEtd = props.quoteSupportEtd
        this.totalDeclaredValue = props.totalDeclaredValue
        this.timezone = props.timezone
        this.totalDeclaredValueCurrency = props.totalDeclaredValueCurrency
        this.totalWeight = props.totalWeight
        this.totalWeightUnit = props.totalWeightUnit
        this.quantity = props.quantity
        this.createdAt = props.createdAt
        this.updatedAt = props.updatedAt
        this.createdByUser = props.createdByUser ? new OtherUser(props.createdByUser) : null
    }
}
