// newProcessData
// deleteProcessData
// editProcessData

import { DimensionUnit, type ApiServiceAction, WeightUnit } from '@lazr/enums'
import { SchedulePickupAttributes, Country } from '@/app/model'


export interface ProcessDataProps {
    fn: (
        data: any,
        countryList: Country[],
        setReturnProcessData: React.Dispatch<React.SetStateAction<SchedulePickupAttributes>>,
        action: ApiServiceAction,
    ) => void
    setReturnProcessData: any
}
//countryList.find((item) => item.code === place.country?.shortName) ?? null,
export const processData = (
    data: any,
    countryList: Country[],
    setReturnProcessData: React.Dispatch<React.SetStateAction<SchedulePickupAttributes>>,
    action: ApiServiceAction,
) => {
    if (action === 'NEW') {
        newProcessData(data, countryList, setReturnProcessData)
    }
}

const newProcessData = (
    data: any,
    countryList: Country[],
    setReturnProcessData: React.Dispatch<React.SetStateAction<SchedulePickupAttributes>>,
) => {
    const defaultValue = data?.defaultData
    const pickupValue = data?.pickupAddressData
    const shipperValue = data?.shipperAddressData
    const customSpecificsValue = data?.customSpecificsData
    const handlingUniValue = data?.handlingUnitData?.[0]

    const pickupCodeCountry = countryList.find((item) => item.name === pickupValue?.country?.value)
    const shipperCodeCountry = countryList.find((item) => item.name === shipperValue?.country?.value)

    const defaultData = {
        carrierId: data.carrierId,
        transportType: defaultValue.transportType,
        date: defaultValue.date,
        openTime: defaultValue.openTime,
        closeTime: defaultValue.closeTime,
        timezone: defaultValue.timezone,
        instructions: null,
        organizationProviderAccountId: null,
        destinationCountry: null,
        notify: null,
        billDutiesToParty: null,
    }

    const addressesData = {
        address: {
            postalCode: pickupValue?.postalCode?.value,
            country: pickupCodeCountry?.code as any,
            state: pickupValue?.state?.value,
            city: pickupValue?.city?.value,
            streetAddress3: pickupValue?.street3?.value ? pickupValue?.street3?.value : '',
            streetAddress2: pickupValue?.street2?.value? pickupValue?.street2?.value:'',
            streetAddress: pickupValue?.street?.value,
            contactPhoneExtension: pickupValue?.contactPhoneExtension?.value ? pickupValue?.contactPhoneExtension?.value : '',
            contactPhone: pickupValue?.contactPhone?.value,
            contactEmails: pickupValue?.contactEmails ? (Object.values(pickupValue?.contactEmails) as string[]) : [],
            contactName: pickupValue?.contactName?.value,
            companyName: pickupValue?.companyName?.value,
        },

        shipperAddress: {
            postalCode: shipperValue?.postalCode?.value,
            country: shipperCodeCountry?.code as any,
            state: shipperValue?.state?.value,
            city: shipperValue?.city?.value,
            streetAddress3: shipperValue?.street3?.value ? shipperValue?.street3?.value : '',
            streetAddress2: shipperValue?.street2?.value? shipperValue?.street2?.value:'',
            streetAddress: shipperValue?.street?.value,
            contactPhoneExtension: shipperValue?.contactPhoneExtension?.value ? shipperValue?.contactPhoneExtension?.value : '',
            contactPhone: shipperValue?.contactPhone?.value,
            contactEmails: shipperValue?.contactEmails ? (Object.values(shipperValue?.contactEmails) as string[]) : [],
            contactName: shipperValue?.contactName?.value,
            companyName: shipperValue?.companyName?.value,
        },
    }

    const dhlData: SchedulePickupAttributes['dhlSpecifics'] = {
        ParcelPakEnvelope: {
            units: [
                {
                    quantity: handlingUniValue?.quantity,
                    dimensionUnit: handlingUniValue?.weightUnit === 'LB' ? DimensionUnit.IN : DimensionUnit.CM,
                    width: handlingUniValue?.width,
                    length: handlingUniValue?.length,
                    height: handlingUniValue?.height,
                    weightUnit: handlingUniValue?.weightUnit === 'LB' ? WeightUnit.LB : WeightUnit.KG,
                    weight: handlingUniValue?.weight,
                },
            ],
            totalWeightUnit: handlingUniValue?.weightUnit === 'LB' ? WeightUnit.LB : WeightUnit.KG,
            totalWeight: handlingUniValue?.totalWeight,
            isResidential: customSpecificsValue?.isResidential?.[1] === 'yes' ? true : false,
            totalDeclaredValueCurrency: customSpecificsValue?.totalDeclaredValueCurrency,
            totalDeclaredValue: customSpecificsValue?.totalDeclaredValue,
            dutyBillToAccountNumber: customSpecificsValue?.dutyBillToAccountNumber,
            quantity: handlingUniValue?.quantity,
            serviceLevelId: customSpecificsValue?.serviceLevelId,
            quoteSupportEtd: null,
        },
    }

    const fedexData: SchedulePickupAttributes['fedexSpecifics'] = {
        ParcelPakEnvelope: {
            destinationDeliveryInstructions: null,
            location: customSpecificsValue?.location?.toUpperCase(),
            serviceLevelCarrierCode: customSpecificsValue?.carrierCode,
            totalWeightUnit: handlingUniValue?.weightUnit,
            totalWeight: handlingUniValue?.totalWeight,
            quantity: handlingUniValue?.quantity,
        },
    }

    const upsData: SchedulePickupAttributes['upsSpecifics'] = {
        ParcelPakEnvelope: {
            totalWeightUnit: handlingUniValue?.weightUnit,
            totalWeight: handlingUniValue?.totalWeight,
            quantity: handlingUniValue?.quantity,
            isResidential: customSpecificsValue?.isResidential ? true : false,
            serviceLevelId: customSpecificsValue?.serviceLevelId,
        },
    }

    const canadaPostData: SchedulePickupAttributes['canadaPostSpecifics'] = {
        ParcelPakEnvelope: {
            quantity: customSpecificsValue?.quantity,
            destinationDeliveryInstructions: null,
        },
    }

    const purolatorData: SchedulePickupAttributes['purolatorSpecifics'] = {
        ParcelPakEnvelope: {
            quantity: handlingUniValue?.quantity,
            totalWeightUnit: handlingUniValue?.weightUnit,
            totalWeight: handlingUniValue?.totalWeight,
        },
    }

    const nationexData: SchedulePickupAttributes['nationexSpecifics'] = {
        ParcelPakEnvelope: {
            quantity: customSpecificsValue?.quantity,
        },
    }

    const canparData: SchedulePickupAttributes['canparSpecifics'] = {
        ParcelPakEnvelope: {
            totalWeightUnit: handlingUniValue?.weightUnit,
            totalWeight: handlingUniValue?.totalWeight,
            quantity: handlingUniValue?.quantity,
            instructions: customSpecificsValue?.instructions,
        },
    }

    const finalData: SchedulePickupAttributes = {
        ...defaultData,
        ...addressesData,
        ...(data.carrierName.toUpperCase() === 'DHL' ? { dhlSpecifics: dhlData } : { dhlSpecifics: undefined }),
        ...(data.carrierName.toUpperCase() === 'FEDEX' ? { fedexSpecifics: fedexData } : { fedexSpecifics: undefined }),
        ...(data.carrierName.toUpperCase() === 'UPS' ? { upsSpecifics: upsData } : { upsSpecifics: undefined }),
        ...(data.carrierName.toUpperCase() === 'CANADA POST'
            ? { canadaPostSpecifics: canadaPostData }
            : { canadaPostSpecifics: undefined }),
        ...(data.carrierName.toUpperCase() === 'PUROLATOR'
            ? { purolatorSpecifics: purolatorData }
            : { purolatorSpecifics: undefined }),
        ...(data.carrierName.toUpperCase() === 'NATIONEX' ? { nationexSpecifics: nationexData } : { nationexSpecifics: undefined }),
        ...(data.carrierName.toUpperCase() === 'CANPAR' ? { canparSpecifics: canparData } : { canparSpecifics: undefined }),
    }

    setReturnProcessData(finalData)
}
