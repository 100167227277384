import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { SnackbarKey } from 'notistack'
import { VersionNotistackAlert } from '@/app/ui-new/layouts/components/NotistackAlert/Version'
import { CookiePolicyNotistackAlert } from '@/app/ui-new/layouts/components/NotistackAlert/CookiePolicy'
import Cookies from 'js-cookie'
import { MessageNotistackAlert } from './components/NotistackAlert/Message'

const NotistackAlerts: React.FunctionComponent<Props> = ({ children, versionSnackbarOpen,  cookiesSnakbarOpen, messageUserSnackbar }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const openNotistack = (content: string | JSX.Element, notistackIdentifier: string) => {
        const wrapper = <div
            className='notistackIdentifier'
            data-notistack-identifier={notistackIdentifier}
        >{content}</div>
        enqueueSnackbar(wrapper, { key: notistackIdentifier, persist:true, preventDuplicate: true })
    }

    const closeNotistack = (event: any) => {
        const target = event.target.closest('.notistackIdentifier')
        const key = target.dataset.notistackIdentifier as SnackbarKey

        closeSnackbar(key)
    }

    const cookiePolicyLawChange = (accept: boolean): void => {
        const aYearFromNow = new Date()
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
        Cookies.set('cookie-law-agreed', accept ? 'true' : 'false', { expires: aYearFromNow })
    }

    const handleAcceptCookiePolicyNotistackAlertClick = useCallback((e: any): void => {
        cookiePolicyLawChange(true)
        closeNotistack(e)
    }, [ cookiePolicyLawChange ])
    const handleDeclineCookiePolicyNotistackAlertClick = useCallback((e: any): void => {
        cookiePolicyLawChange(false)
        closeNotistack(e)
    }, [ cookiePolicyLawChange ])

    if (cookiesSnakbarOpen) {
        openNotistack(<CookiePolicyNotistackAlert
            open={cookiesSnakbarOpen}
            handleAcceptClick={handleAcceptCookiePolicyNotistackAlertClick}
            handleDeclineClick={handleDeclineCookiePolicyNotistackAlertClick}/>, 'cookie_policy')
    }

    if (versionSnackbarOpen) {
        openNotistack(<VersionNotistackAlert open={versionSnackbarOpen}/>, 'version_update')
    }

    if (messageUserSnackbar) {
        //openNotistack(<MessageNotistackAlert open={messageUserSnackbar.open} messageCookieName={messageUserSnackbar.cookieName} expiresDays={messageUserSnackbar.expiresDays}/>, 'message_update')
    }

    return (
        <div> {children} </div>
    )
}

export interface Props {
    versionSnackbarOpen?: boolean
    cookiesSnakbarOpen?: boolean
    messageUserSnackbar?: {open: boolean, cookieName: string, expiresDays: number}
}

export default NotistackAlerts
